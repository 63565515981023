
	.top-bar {
		background: $cfourLightGray;
		position: relative;
		margin-bottom: 15px;
		.cfour-cachet {
			position: relative;
			.cachet {
				position: absolute;
				left: 0;
				z-index: 9999;
				top: 0;
				background: #fff;
				box-shadow: 0 3px 17px rgba(0,0,0,0.1);
				padding: 15px 0 15px 15px;
				width: auto;
				img {
					border-style: none;
					width: 100px;
					padding-right: 15px;
				}
			}
		}
		.controls-list {
            .control-user {
                #login-change {
					.dropdown {
						#accountMenuList {
							background-color: $cfourTertiaryColor;
							& > span {
								color: $cfourWhite;
							}
							&:after {
								color: $cfourWhite;
							}
						}
						.account-menu {
							.list-group-item {
								color: $cfourPrimaryColor;
								background-color: $cfourLightGray;
							}
						}
					}
                }
            }
			.control-languages {
				a[aria-expanded="true"] {
					background: $cfourSecondaryColor;
				}
			}
			.control-search {
				a[aria-expanded="true"] {
					background: $cfourSecondaryColor;
				}
			}
			.control-wish-list {
				a {
					i.fa-heart {
						color: $cfourPrimaryColor;
					}
				}
			}
			.control-basket {
				.toggle-basket-preview {
					background-color: $cfourTertiaryColor;
					color: $cfourWhite;
					&:hover {
						background-color: $cfourTertiaryColor;
					}
				}
			}
            li{
              a{
                font-size: .9em;
                color: $cfourPrimaryColor;
                text-transform: uppercase;
                &:hover {
                  background: $cfourSecondaryColor;
                  color: $cfourPrimaryColor;
                }
              }
              a[aria-expanded="true"] {
                background: $cfourSecondaryColor;
                font-size: .9em;
                color: $cfourPrimaryColor;
                text-transform: uppercase;
              }
            }
			&>li{
				a{
					font-size: .9em;
					color: $cfourPrimaryColor;
					text-transform: uppercase;
					min-height: 30px;
					outline: none;
					&:hover {
						background: $cfourSecondaryColor;
						color: $cfourPrimaryColor;
					}
				}
                a[aria-expanded="true"] {
                  background: $cfourSecondaryColor;
                  font-size: .9em;
                  color: $cfourPrimaryColor;
                  text-transform: uppercase;
                }
			}
		}
		.cmp-currency-select, .cmp-country-settings {
			background: #282d2f;
		}
	}
	.navbar {
		background: #efefef;
		border-bottom: none;
		@media (max-width: 991px) {
			z-index: 99999 !important;
		}
		& > .container-max > .row {
			flex-direction: column;
			.brand-wrapper {
				a {
				  display: none !important;
				}
				@media (max-width: 991px) {
					top: -5rem;
					position: absolute;
					right: 0;
				}
			}
		}
		.navbar-toggler {
			color: #000;
		}
	}
	#page-body {
		margin-top: 0;
	}


	.header-logo-block {
		background: #fff !important;
		& > .widget-inner {
			@media (min-width: 1200px) {
				width: 1200px;
				margin: auto;
			}
			.navbar-brand {
				display: flex;
				justify-content: center;
				height: 6rem;
				img {
					max-height: 4rem;
				}
			}
		}
	}


@include media-breakpoint-down(md) {
		.top-bar {
			.cfour-cachet {
				.cachet {
					top: 30px;
					padding: 7px 0 7px 7px;
					width: auto;
					img {
						border-style: none;
						width: 40px;
						padding-right: 10px;
					}
				}
			}
		}

}

@include media-breakpoint-down(pxs) {
		.top-bar {
			.cfour-cachet {
				.cachet {
					top:33px;
					padding: 7px 0 7px 7px;
					width: auto;
					img {
						border-style: none;
						width: 40px;
						padding-right: 10px;
					}
				}
			}
		}
}
@include media-breakpoint-down(xs) {
		.top-bar {
			.cfour-cachet {
				.cachet {
					top:33px;
					padding: 7px 0 7px 7px;
					width: auto;
					img {
						border-style: none;
						width: 40px;
						padding-right: 10px;
					}
				}
			}
		}

}
