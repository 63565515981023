.breadcrumbs {
	padding: 5px 0;
	background: $cfourWhite;
	.breadcrumb {
		background: $cfourWhite;
		a {
			color: $cfourPrimaryColor;
		}
	}
}


.page-wish-list, .page-contact, .page-search, .page-confirmation  {
	.breadcrumbs {
		display: none;
		a {
			color: $cfourPrimaryColor;
		}
	}
}
