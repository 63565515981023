body > #vue-app {
  background-color: $cfourWhite;
}

.btn.btn-right, .btn > .fa {
  float: none;
}

.page-home-category {
  .widget-item-list {
     .widget-caption {
       background: transparent !important;
       padding: 5px 0;
       border-bottom: 1px solid $cfourLightGray;
       margin-bottom: 30px !important;
       p {
         color: $cfourGrayFont;
         font-weight: 400;
         font-size: 16px;
         text-transform: uppercase !important;
       }
       a {
         color: $cfourGrayFont !important;
         right: 0;
       }
     }
  }
  .featured-categories {
    .widget-caption {
      padding: 0;
      background: transparent;
    }
    p {
      font-size: 1.75rem;
      padding: 1.5rem;
      background: #ede8e5;
      color: #6b645e;
    }
  }
}



.sale-new {
  .featured-sale-new{
    img {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
    .featured-category-headline {
      color: #6b645e;
    }
  }
}

