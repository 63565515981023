.featured-category {
    .featured-category-headline {
        background: $cfourSecondaryColor;
        color: $cfourPrimaryColor;
    }
    &:hover {
        .featured-category-headline {
            background: $cfourSecondaryColor;
        }
    }
}