#page-body {
  .owl-carousel {
    .carousel-control:hover i {
      color: $cfourTertiaryColor;
    }
    @media (min-width: 768px) {
      .carousel-control.right {
        margin-right: 5px;
      }
    }
    .owl-dot {
      span {
        background-color: $cfourPrimaryColor;
      }
      &:hover span {
        border: 1px solid $cfourPrimaryColor;
        background-color: $cfourWhite;
      }
      &.active span {
        border: 1px solid $cfourPrimaryColor;
        background-color: $cfourWhite;
      }
    }
  }
  .single {
    .single-rightside {
      .CfourArticleHeader {
        text-transform: uppercase;
      }
      hr {
        border-top: 1px solid $cfourPrimaryColor;
      }
      .CfourArticleTable {
        width: 100%;
      }
      .producerimage {
        img {
          max-width: 100%;
        }
      }
      .single-description {
        font-size: 14px;
      }
      .input-unit {
        width: 33.333%;
        float: left;
        .custom-select {
          padding: 10px 15px;
          font-weight: bold;
          color: $cfourPrimaryColor;
        }
      }
      label {
        float: left;
        line-height: 36px;
        margin-left: 15px;
        font-weight: bold;
      }
      .crossprice del{
        color: $cfourSecondaryColor;
        font-size: 24px;
      }
      .price {
        font-weight: bold;
        font-size: 40px;
        color: #d5157d;
        margin-top: 2.5rem;
      }
      .vat {
         color: $cfourPrimaryColor;
         font-weight: normal;
         font-size: 10px;
         a {
           text-decoration: underline;
           color: inherit;
         }
       }
      @media (max-width: 991px) and (min-width: 768px) {
        .price {
          float: left !important;
        }
      }
      .CfourAddToBasket {
        .CfourAddToBasketButton {
          background: $cfourTertiaryColor;
          border: $cfourTertiaryColor;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 0.9em;
          padding: .7em 0;
        }
      }
      .add-to-wish-list {
        color: $cfourPrimaryColor;
        font-weight: bold;
        a {
          font-weight: 700;
          padding: .5rem 0;
          .fa-heart {
            padding: 7.5px;
            border: 1px solid #cccccc;
            color: $cfourPrimaryColor;
            font-size: 16px;
            margin-right: 15px;
          }
        }
        &:hover {
          .fa-heart {
            color: $cfourTertiaryColor !important;
          }
        }
      }
      #CfourNotificationForm {
        button {
          background: $cfourTertiaryColor;
          color: $cfourWhite;
        }
      }
    }
    .availability-1, .availability-2 {
      background-color: #7ED321;
    }
    .tag {
      display: inline-block;
      padding: .25em .4em;
      font-size: 75%;
      line-height: 1;
      color: #fff;
      text-align: center;
      vertical-align: baseline;
      border-radius: .1rem;
    }
    .base-price {
      display: none;
    }
    .single-item-feedback-block {
      .btn-primary {
        background: #d5157d !important;
        border-color: #d5157d !important;
      }
    }
    table {
      border-collapse: unset;
      td {
        border: none;
      }
    }
  }
  .nav-tabs {
    .nav-link {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-right: 2px solid $cfourWhite;
      color: $cfourPrimaryColor;
      background: $cfourSecondaryColor;
      padding-bottom: 5px;
      padding-top: 5px;
    }
    .nav-link:hover {
      color: $cfourTertiaryColor;
    }
    .nav-link.active {
      color: $cfourTertiaryColor;
    }
  }
}
