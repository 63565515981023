.add-item {
  .fa {
    color: $cfourSecondaryColor;
    border-color: $cfourSecondaryColor;
    &:hover {
      color: $cfourSecondaryColor;
      border-color: $cfourSecondaryColor;
    }
  }
}

.cmp-address-list {
  .item input:checked {
    &+ .item-inner {
      background-color: $cfourSecondaryColor;
      color: $cfourWhite;
    }
  }
}