.page-content {
  background: $cfourWhite;
}

.quantity-input-container,.qty-box-container {
  .input-group {
    flex-wrap: nowrap;
    display: flex;
    button {
      background: #fff;
      border: 1px solid #ccc;
      color: #4d4d4d;
      padding: 5px 14px;
    }
    input {
      color: $cfourPrimaryColor;
      border: 1px solid #ccc;
      border-radius: 0;
      padding-left: 1rem;
      text-align: left !important;
      &:focus {
        border: 1px solid $cfourPrimaryColor !important;
        border-radius: 0 !important;
      }
    }
  }
}


.quantity-input-container {
  .input-group {
    input {
      width: 10rem !important;
    }
  }
}

.qty-box-container {
  .input-group {
    input {
      width: 2.5rem !important;
    }
  }
}


.basket-item {
   a {
     text-transform: none !important;
   }
}


.basket-preview-footer {
  .btn-primary {
    color: $cfourWhite !important;
  }
}

.method-list-item[data-id="24"] .icon {
  background-image: url(https://cdn03.plentymarkets.com/crykgdsnx6pf/frontend/Versand/selbstabholung.png);
  width: 50px;
  height: 56px;
}
