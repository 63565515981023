.cfour-category-banner {
    position: relative;
    img {
        width: 100%;
    }
    .cfour-category-text-wrapper {
        margin: 0 15px;
    }
    @media (min-width: 768px) {
        .cfour-category-text-wrapper {
            position: absolute;
            bottom: 0;
            h1 {
              color: #fff;
              font-size: 500%;
              text-shadow: 0 0 0.1em rgba(0,0,0,.75);
              width: 100%;
            }
        }
    }
}

.page-category {
    .parallax-img-container {
        .parallax-img-container-inner[style^="background-image"] {
            background-attachment: unset;
            background-size: cover !important;
        }
        .category-title {
            color: $cfourWhite;
            text-shadow: 0 0 0.1em rgba(0,0,0,.75);
            width: 100%;
            margin-left: 1rem;
        }
    }
    .widget-toolbar.list-controls {
        a[data-toggle="collapse"] {
            display: none !important;
        }
    }
    .pagination {
        .page-item {
            &.active {
                .page-link {
                    border-color: #d5157d;
                    background-color: #d5157d;
                }
            }
            .page-link {
                color: #d5157d;
            }
        }
    }
}






.widget-item-list, .product-list {
    .cmp-product-thumb {
        border: 1px solid $cfourLightGray;
        background-color: transparent;
        padding-bottom: 15px;

        .thumb-background, .main-image, .thumb-content {
            background-color: transparent;

            .row-custom {
                display: flex;
                @media (min-width: 992px) {
                    flex-direction: row;
                }
                @media (max-width: 991px) {
                    flex-direction: column;
                    width: 100%;
                }

                .thumb-title {
                    display: flex;
                    @media (min-width: 1200px) {
                        min-width: 182px;
                    }
                }

                .category-grid-basket-button {
                    @media (min-width: 992px) {
                        width: 24%;
                        text-align: right;
                    }

                    .btn-group {
                        margin-top: 0;

                        button {
                            border-radius: 0 !important;
                            background: #d5157d;
                            border-color: #d5157d;
                        }
                    }
                }
            }

            .category-grid-basket-button-mobile {
                .btn-group {
                    margin-top: 0;

                    button {
                        border-radius: 0 !important;
                        background: #d5157d;
                        border-color: #d5157d;
                        @media (max-width: 991px) {
                            display: flex;
                            justify-content: center;
                            flex-direction: row-reverse;
                            span {
                                margin-right: .5rem;
                            }
                        }
                    }
                }
            }
        }

        .add-to-basket-lg-container {
            border-color: transparent $cfourTertiaryColor transparent transparent;

            & > i {
                color: $cfourWhite;
            }

            &:hover {
                border-color: transparent $cfourTertiaryColor transparent transparent;

                & > i {
                    color: $cfourWhite;
                }
            }
        }

        .crossprice {
            color: $cfourPrimaryColor;
            font-weight: 400;
            font-size: 17px;
            line-height: 1.1;
        }

        .price {
            color: $cfourPrimaryColor;
            font-weight: 900;
            font-size: 20px;
            text-align: right;
        }

        .price-col {
            padding: 0;
            left: 50%;
        }

        .price-view-port-col {
            padding: 0;
            text-align: right;

            & + .price-col {
                left: 0;
            }
        }

        .vat-porto-info, .vat {
            text-align: right;
            display: inline-block;
            width: 100%;
            margin-top: 6px;
            color: $cfourPrimaryColor !important;

            & > a {
                color: $cfourPrimaryColor !important;
                text-decoration: underline;
            }
        }

        .special-tags {
            padding: 18px;

            .special-tag, .tag {

                &.bg-danger {
                    background-color: #FFCBFF !important;
                    color: $cfourPrimaryColor !important;

                    & > i {
                        padding: 5px 7px;
                    }

                    &::after {
                        border-color: transparent #ffcbff transparent;
                    }
                }

                &.bg-primary {
                    background-color: #C4DCFE !important;
                    color: $cfourWhite !important;

                    & > i {
                        padding: 5px 6px;
                    }

                    &::after {
                        border-color: transparent #C4DCFE transparent;
                    }
                }

                &.bg-success {
                    background-color: #30f292 !important;
                    color: $cfourWhite !important;

                    & > i {
                        padding: 2px 12px;
                        font-size: 31px;
                    }

                    &::after {
                        border-color: transparent #30f292 transparent;
                    }
                }

                // @TODO: Add .bg-success ("Top item")

                border-radius: 5px;
                padding: 5px;
                box-shadow: none;
                position: relative;

                & > i {
                    font-size: 25px;
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 8px;
                    bottom: -12px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 15px 12px 0;
                }
            }
        }

        .thumb-title {
            font-size: 12px;
            text-transform: uppercase;
            max-width: 75%;
            color: $cfourPrimaryColor;
            height: 96px;
            overflow: hidden;
            line-height: 1.5em;

            & > p {
                max-height: 50px;
                overflow: hidden;
                margin: 0;
            }

            & > small {
                text-transform: none;
                font-size: 100%;
                line-height: 30px;
                display: inline-block;
                margin-bottom: 10px;
            }
        }

        .thumb-title:after {
            content: "";
            width: 100%;
            display: block;
            height: 2em;
            position: absolute;
            left: 0;
            bottom: 0;
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.98) 50%, rgba(255, 255, 255, 1) 100%);
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.98) 50%, rgba(255, 255, 255, 1) 100%);
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.98) 50%, rgba(255, 255, 255, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
        }

        .category-list-view-port {
            display: block;

            .btn-group {
                opacity: 1;
                top: .8rem;
                bottom: auto;

                button {
                    background-color: $cfourTertiaryColor;
                    color: $cfourWhite;

                    i {
                        display: inline-block;
                        padding-bottom: 3px;
                    }

                    @media (max-width: 991px) {
                        i {
                            float: right;
                            padding-bottom: 0;
                            margin-top: 2px;
                        }
                    }
                }
            }
        }

        .thumb-meta {
            .category-unit-price {
                font-size: .81em;
                color: #b2b1a7;
            }
        }

        @media (max-width: 400px) {
            .thumb-content, .thumb-image {
                width: 100%;
            }
            .price-view-port-col {
                text-align: left;
            }
        }

        @include media-breakpoint-down(lg) {
            .price, .crossprice {
                font-size: 16px;
            }
        }

        @include media-breakpoint-down(md) {
            .mobile-icon-right {
                float: none;
            }
        }

        @include media-breakpoint-down(pxs) {
            .price, .crossprice {
                font-size: 14px;
                min-height: unset;
                line-height: 20px;
            }
        }

        @include media-breakpoint-down(xs) {
            .special-tags {
                padding: 0;
                margin: 5px;

                .tag {
                    padding: 0;

                    & > i {
                        font-size: 18px;
                    }

                    &::after {
                        right: 6px;
                        bottom: -7px;
                        border-width: 0 10px 7px 0;
                    }

                    &.bg-success > i {
                        padding: 4px 11px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
