.main-navbar-collapsable {
	background: $cfourLightGray;
	ul.mainmenu{
		justify-content: center;
		li {
			/*&:hover {
				background-color: transparent;
				color: $cfourTertiaryColor;
			}*/
			&>ul {
				background: $cfourWhite;
				/*&>li {
					color: $cfourPrimaryColor;
					&:hover {
						background: $cfourSecondaryColor;
						color: $cfourTertiaryColor;
					}
				}*/
			}
			.collapse {
				background: $cfourWhite;
				li {
					.collapse-inner {
						li {
							padding: .1em 0!important;
							text-align: left;
							a {
								color: #000 !important;
								padding: .1px 1.5em;
								background: #fff;
							}
							&.level1, &.level2, &.level3 {
								border-top: 3px solid transparent;
								border-bottom: 3px solid transparent;
								&:hover {
									border-top: 3px solid #ede8e5;
									border-bottom: 3px solid #ede8e5;
									a {
										color: $cfourPrimaryColor !important;
									}
								}
							}
						}
					}
				}
				& > li {
					padding: 0 !important;
				}
			}
		}
		& > li {
			& > a {
				color: $cfourPrimaryColor !important;
			}
			&:hover {
				background: $cfourWhite;
			}
		}
	}
}

/*nav.navbar.megamenu {
	 @include media-breakpoint-up(lg){ position : relative; }
	.main-navbar-collapsable {

		 @include media-breakpoint-up(lg){
			 ul.mainmenu {
				 justify-content : center;
				 &>li {
					 &:hover {
						 color 	   : $cfourPrimaryColor !important;
					 }

					 &>ul.collapse {
						& >li {

							 &.level1 {
								& >a {
									 color 		: $cfourPrimaryColor;
								 }
							 }
							 a {
								 color 		: $cfourPrimaryColor;

								 &:hover{
									 color 		: $cfourPrimaryColor !important;
								 }
							 }
						 }

					 }

				 }
			 }
		 }
	 }
 }*/

.mobile-navigation {
	color: $cfourPrimaryColor;
	background: $cfourWhite;
	ul.breadcrumb {
		background: $cfourSecondaryColor;
		color: $cfourWhite;
	}
}
