@import "_variables";
@import "_breakpoints";

// Additional SASS for other plugins
@import "_additional";

// global
@import "custom/common/card";
@import "custom/common/common";
@import "custom/common/buttons";
@import "custom/common/structure";

// Page Design / Global components
@import "custom/views/Item/single-item";
@import "custom/views/PageDesign/breadcrumb";
@import "custom/views/PageDesign/header";
@import "custom/views/PageDesign/footer";
@import "custom/views/PageDesign/navigation";
@import "custom/views/PageDesign/search-box";
@import "custom/views/PageDesign/language-select";
@import "custom/views/Templates/CategoryListItem/category-list-item";

// Components
@import "custom/components/featured-category";
@import "custom/components/featured-sale-new";
@import "custom/components/anicon";

// Templates
@import "custom/views/Templates/AddressSelect/address-select";

// Pages
@import "custom/views/ItemList/loading-animation";
@import "custom/views/Home/home";

@font-face {
  font-family: "Roboto-Light";
  src: url("../fonts/Roboto-Light.ttf");
}
