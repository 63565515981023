.cmp-country-settings {
    position: absolute;
    left: 0;
    right: 0;
    transform: unset;

    ul{
        li.active {
            a {
                background-color:$cfourSecondaryColor;
                color: $cfourPrimaryColor;
                &:hover {
                    background-color:$cfourSecondaryColor;
                    color: $cfourPrimaryColor;
                }
            }
        }
        li {
            a {
                &:hover {
                    background-color:$cfourSecondaryColor;
                    color: $cfourPrimaryColor;
                }
            }
        }
    }
}