body {
	background-color: $cfourWhite;
	color: $cfourPrimaryColor;

	a {
		outline: none;
	}


	.maps-component{
		min-height: 300px;
	}


	.startpage-header {
		margin-top: 1.25em;
		.col-xl-8 {
			@media (min-width: 1200px) {
				padding-right: 0;
			}
			@media (min-width: 768px) and (max-width: 991px) {
					flex: 0 0 100%;
					max-width: 100%;
			}
		}
		.col-xl-4 {
			@media (min-width: 768px) and (max-width: 991px) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
		.img-box-1 {
			margin-bottom: 1.05em !important;
			@media (min-width: 1200px) {
				padding-bottom: 68.7% !important;
			}
		}
		.img-box-2 {
			@media (min-width: 1200px) {
				padding-bottom: 68.6% !important;
			}
		}
		.carousel-control .fa {
			color: $cfourWhite !important;
			@media (min-width: 1200px) {
				font-size: 60px !important;
			}
		}
		.carousel-indicators {
			li {
				width: 10px !important;
				height: 10px !important;
				&.active {
					width: 12px !important;
					height: 12px !important;
					background: $cfourWhite !important;
					border-color: #000;
				}
				background: #6b645e;
				border-color: #6b645e !important;
				&:hover {
					background: $cfourWhite !important;
				}
			}
		}
	}
}
